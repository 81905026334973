<template>
  <div>
    <title>SIM INVENTORY ~ ADD PRODUCT DATA PROCESS DETAILS BOM</title>
    <section class="content-header">
      <h1>
        Add Product Data Process Details BOM
        <br />
        <h4>
          Please enter the product transaction data for the BOM detail process
        </h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Product Transaction Process Details BOM</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Product Data Process Details BOM</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Process</label>
                  <select
                    class="form-control"
                    v-model="isidata.proses"
                    id="proses"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="brgproses in prosesbrg"
                      :key="brgproses.kode_proses"
                      :value="brgproses.kode_proses"
                    >
                      {{ brgproses.nama_proses }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.catatan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Please Enter Notes"
                  />
                  <input
                    type="hidden"
                    v-model="isidata.idxprodukdbom"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Please Enter Notes"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <button @click="kembali()" class="btn btn-success">
                  <i class="fa fa-angle-double-left"></i> Back
                </button>
                <!-- <router-link to="/produk">
                  <button class="btn btn-success">Kembali</button>
                </router-link> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addprod",
  data() {
    return {
      loading: false,
      prosesbrg: [],
      isidata: {
        proses: "",
        catatan: "",
        idxprodukdbom: ""
      }
    };
  },
  created() {
    this.getproseslist();
    this.isidata.idxprodukdbom = sessionStorage.getItem("idprodprosbom");
  },
  methods: {
    kembali() {
      this.$router.push({
        name: "produkprosesbom",
        params: { id: this.isidata.idxprodukdbom }
      });
    },
    getproseslist() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getbarangbykode_barang?kode_barang=" + this.isidata.kodebarang;
      const urlAPIget = this.$apiurl + "daftar_proses/getdaftar_proses_list";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.prosesbrg = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async saveData() {
      this.loading = true;
      var pros = this.isidata.proses;
      var nts = this.isidata.catatan;

      if (pros == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Process can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nts == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        var kodeuser = sessionStorage.getItem("kodeuser");
        var paramdata = {
          id_produk_d_bom: this.isidata.idxprodukdbom,
          kode_proses: this.isidata.proses,
          notes: this.isidata.catatan,
          kode_user: kodeuser
        };

        var tokenlogin = sessionStorage.getItem("token");
        // var urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/produk_d_proses/saveproduk_d_proses";
        var urlAPIsavedata =
          this.$apiurl + "produk_d_bom_proses/saveproduk_d_bom_proses";

        var headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({
                name: "produkprosesbom",
                params: { id: this.isidata.idxprodukdbom }
              });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
